@tailwind base;

.text-center-important {
  text-align: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer base {
  html,
  #app-root {
    @apply text-white;
    @apply bg-white;

    font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    line-height: 1.5;
  }

  html {
    overflow-x: hidden;
    height: 100%;
  }

  body {
    height: 100%;
  }

  body > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  h1 {
    @apply text-2xl font-black;
  }

  h2 {
    @apply text-lg font-bold;
  }

  h3 {
    @apply text-lg;
  }
}

@tailwind components;
@tailwind utilities;

/* Shadow DOM Animation */
@keyframes spinners-react-circular {
  0% {
    stroke-dashoffset: 306;
  }
  50% {
    stroke-dasharray: 40, 134;
  }
  100% {
    stroke-dasharray: 1, 174;
    stroke-dashoffset: 132;
  }
}
